.listWrapper {
    width: 100%;
    max-height: 60%;
    flex-grow: 1;
}


.list {
    margin-top: 1em;
    width: 100%;
    max-height: 70%;
    display: flex;
    flex-direction: column;
    list-style: none;
    overflow-y: auto;
}

@media only screen and (min-width: 900px) {
    .listWrapper {
        max-height: 70%;
    }

    .list {
        max-height: 80%;
    }
}

.listItem {
    border-bottom: 1px solid darkgray;
    display: flex;
    justify-content: space-between;
}

.listItem span {
    margin: 0.2em 0.5em;
}