/* Background Image */
.bg {
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: -1;
}

.bgImage,
.overlay1,
.overlay2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bgImage {
  background: #333;
  background-image: url("../assets/images/webp/background.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bgImage {
  filter: grayscale(1);
}

.overlay1 {
  background: #f06b12;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  mix-blend-mode: multiply;
}

.overlay2 {
  mix-blend-mode: lighten;
  background: #00cdef;
  opacity: 0.5;
}