.wrapper {
    margin-bottom: 1em;
}

.control {
    background-color: lightgray;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    width: 100%;
    height: 33.4333px;
}

.control input {
    border: 1px solid rgba(27, 31, 35, 0.15);
}

.control input[type="number"] {
    width: 70%;
}

.control input[type="text"] {
    width: 30%;
}

.control input {
    border: 1px solid rgba(27, 31, 35, 0.15);
}

.control input:focus {
    outline: none;
}