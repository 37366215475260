/* Background Image */
.bg {
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: -1;
}

.bgImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bgImage {
  background: #333;
  background-image: url("../../assets/images/webp/christmas-background-mobile.webp");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 900px) {
  .bgImage {
    background: #333;
    background-image: url("../../assets/images/webp/christmas-background-desktop.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}