.wrapper {
    text-align: center;
    margin-top: 20%;
    height: 100%;
}

.introTitle {}

.receiver {
    margin: 1em;

}

.outroTitle {
    color: darkgray;
    font-size: 14px;
}