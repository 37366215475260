.listWrapper {
    width: 100%;
    max-height: 60%;
    flex-grow: 1;
}


.list {
    width: 100%;
    max-height: 70%;
    display: flex;
    flex-direction: column;
    list-style: none;
    overflow-y: auto;
}

@media only screen and (min-width: 900px) {
    .listWrapper {
        max-height: 70%;
    }

    .list {
        max-height: 80%;
    }
}

.listItem {
    border-bottom: 1px solid darkgray;
    display: flex;
    justify-content: space-between;
}

.listItem span {
    margin: 0.2em 0.5em;
}

.control {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5em;
}

.control input {
    border: 1px solid black;
    width: 100%;
    border: 1px solid rgba(27, 31, 35, 0.15);
}

.control input:focus {
    outline: none;
}

.control span {
    border-radius: 0 !important;
}

.participantRemoveButton {
    color: darkgray;
}

.button:hover {
    cursor: pointer;
}