.wrapper {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 21em;
    margin: 2em auto;
    height: 65%;
    padding: 1em 2em;
}

.control {
    align-self: flex-end;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

@media only screen and (min-width: 900px) {
    .wrapper {
        background-color: rgba(248, 248, 248, 0.9);
        margin: 3em auto;
        padding: 3em 3em 0 3em;
    }
}

.titleWrapper {
    color: darkgreen;
    display: flex;
    justify-content: space-around;
    padding-top: 2em;
    font-family: "Mountains of Christmas";
}