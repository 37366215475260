html,
body,
main,
.page {
  height: 100%;
}

html {
  overflow: hidden;
}

body {
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-size: 1.1em;
  font-family: 'Fira Mono', 'Calibri', monospace;
  line-height: 1.5;
}

a {
  color: inherit;
  text-decoration: underline;
}

a:hover {
  cursor: pointer;
}

h1,
h2,
h3,
p {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 4em;
  line-height: 0.85;
}

h2 {
  font-size: 1em;
}

h3 {
  font-size: 0.75em;
}

p {
  font-weight: lighter;
  font-size: 0.75em;
}

ul,
ul li {
  margin: 0;
  padding: 0;
}

input {
  padding-left: 10px;
  padding-right: 10px;
}

.button {
  text-align: center;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 16px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
}

/* Buttons Start */
.button.secondary {
  background-color: #696969;
}

.button.primary {
  background-color: darkgreen;
}

.button.loading {
  cursor: progress;
  background-color: #696969;
}

.button:hover {
  text-decoration: none;
  transition-duration: 0.1s;
  cursor: pointer;
}

.button.primary:hover {
  background-color: rgb(0, 85, 0);
}

.button.secondary:hover {
  background-color: #494949;
}

.button.complete, .button.complete:hover {
  background-color: #cccccc;
  color:darkgreen;
  cursor: default;
}

.button.error, .button.error:hover {
  background-color: #cccccc;
  color: darkred;
  cursor: default;
}

.button.disabled, .button.disabled:hover {
  background-color: #cccccc;
  color: darkred;
  cursor: not-allowed;
}

/* Buttons End */