.landingPageContainer {
  padding-top: 10vh;
  margin: 0 10%;
  height: 70vh;
  max-height: 450px;
  width: 16em;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  text-transform: uppercase;
  color: #fff;
}

.description,
.rightNow,
.getInTouch {
  padding-left: 8px;
}

.description {
  text-transform: capitalize;
}

.getInTouch {
  width: 250px;
}

.getInTouch ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  font-weight: lighter;
  font-size: 0.75em;
}

.getInTouch ul li:not(:first-child) {
  margin-left: 5px;
}

.getInTouch ul li:not(:last-child) {
  margin-right: 5px;
}

@media only screen and (min-width: 900px) {
  .landingPageContainer {
    flex-flow: column wrap;
    justify-content: flex-start;
    max-width: 1080px;
    max-height: 300px;
    margin: 0 10%;
    padding-top: 30vh;
    min-width: 700px;
    width: 50%;
  }

  .title {
    order: 0;
    height: 50%;
  }

  .description {
    order: 1;
    height: 40%;
  }

  .rightNow {
    margin-left: 25%;
    order: 2;
    height: 25%;
  }

  .getInTouch {
    height: 25%;
    order: 3;
    margin-left: 25%;
  }
}
